import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { adminURL } from './config'

/* Estos son los Pages que se van a usar en toda la APP */

const Register = lazy( () => import('./pages/register/register') )
const Room = lazy( () => import('./pages/room/room') )
const Admin = lazy( () => import('./pages/admin/admin') )
const Agenda = lazy( () => import('./pages/agenda/agenda') )
const Speakers = lazy( () => import('./pages/speakers/speakers') )
const P404 = lazy( () => import('./pages/page404/page404') )
const Questions = lazy( () => import('./pages/questions/questions') )
// const Cert = lazy( () => import('./components/certificate/certificate') )
const Cover = lazy( () => import('./pages/cover/cover') )

/*
  Hay varias versiones de React router DOM.
  La base de todos los eventos se hizo con una version anterior, El constructor tiene la nueva forma de usar el router
*/
function Routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}> {/* Investigar que es Suspense y Lazy import */}
        <Switch>
          {/* <Route exact path={['/industria-cannabis']} component={Cover}/> */}
          <Route exact path={['/industria-cannabis', '/industria-cannabis/login', '/industria-cannabis/register']} component={Register}/>
          <Route exact path="/industria-cannabis/room" component={Room}/>
          <Route exact path="/industria-cannabis/agenda" component={Agenda}/>
          <Route exact path="/industria-cannabis/speakers" component={Speakers}/>
          <Route exact path="/industria-cannabis/preguntas-speaker" component={Questions}/>
          <Route exact path={adminURL} component={Admin}/>
          <Route component={P404}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;
